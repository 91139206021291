/* Generated by Glyphter (http://www.glyphter.com) on  Wed Sep 06 2023*/
@font-face {
    font-family: 'Perfect features';
    src: url('../fonts/Perfect-features.eot');
    src: url('../fonts/Perfect-features.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Perfect-features.woff') format('woff'),
         url('../fonts/Perfect-features.ttf') format('truetype'),
         url('../fonts/Perfect-features.svg#Perfect-features') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Perfect features';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-Double-Check:before{content:'\0041';}
.icon-Check:before{content:'\0042';}
.icon-clock:before{content:'\0043';}
.icon-Like:before{content:'\004c';}
.icon-Perfect:before{content:'\0050';}
.icon-schedule_send:before{content:'\0053';}
.icon-Pass:before{content:'\0058';}
.icon-Like-Outlined:before{content:'\006c';}
.icon-text-token:before{content:'\0074';}
.icon-Pass-Outlined:before{content:'\0078';}
@import url("../../assets/fonts/Gilroy/font.css");
@import url("../../assets/fonts/Merriweather/font.css");
@import url("../../assets/fonts/Poppins/font.css");
@import url("../../assets/fonts/perfect-features-font/css/perfect-features.css");

* {
  padding: 0;
  margin: 0;
  font-family: 'Gilroy';
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  padding: 0;
  margin: 0;
  font-family: Poppins;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}
@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 100;
    src: url(./GilroyThinItalic/font.woff) format('woff');
    src: url(./GilroyThinItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 100;
    src: url(./GilroyThin/font.woff) format('woff');
    src: url(./GilroyThin/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 200;
    src: url(./GilroyUltraLightItalic/font.woff) format('woff');
    src: url(./GilroyUltraLightItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 200;
    src: url(./GilroyUltraLight/font.woff) format('woff');
    src: url(./GilroyUltraLight/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 300;
    src: url(./GilroyLightItalic/font.woff) format('woff');
    src: url(./GilroyLightItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    src: url(./GilroyLight/font.woff) format('woff');
    src: url(./GilroyLight/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 400;
    src: url(./GilroyRegularItalic/font.woff) format('woff');
    src: url(./GilroyRegularItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url(./GilroyRegular/font.woff) format('woff');
    src: url(./GilroyRegular/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 500;
    src: url(./GilroyMediumItalic/font.woff) format('woff');
    src: url(./GilroyMediumItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 500;
    src: url(./GilroyMedium/font.woff) format('woff');
    src: url(./GilroyMedium/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 600;
    src: url(./GilroySemiBoldItalic/font.woff) format('woff');
    src: url(./GilroySemiBoldItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    src: url(./GilroySemiBold/font.woff) format('woff');
    src: url(./GilroySemiBold/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 700;
    src: url(./GilroyBoldItalic/font.woff) format('woff');
    src: url(./GilroyBoldItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    src: url(./GilroyBold/font.woff) format('woff');
    src: url(./GilroyBold/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 800;
    src: url(./GilroyExtraBoldItalic/font.woff) format('woff');
    src: url(./GilroyExtraBoldItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    src: url(./GilroyExtraBold/font.woff) format('woff');
    src: url(./GilroyExtraBold/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 900;
    src: url(./GilroyBlackItalic/font.woff) format('woff');
    src: url(./GilroyBlackItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 900;
    src: url(./GilroyBlack/font.woff) format('woff');
    src: url(./GilroyBlack/font.woff2) format('woff2');
}

@font-face {
    font-family: 'Gilroy';
    font-style: italic;
    font-weight: 1000;
    src: url(./GilroyHeavyItalic/font.woff) format('woff');
    src: url(./GilroyHeavyItalic/font.woff2) format('woff2');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 1000;
    src: url(./GilroyHeavy/font.woff) format('woff');
    src: url(./GilroyHeavy/font.woff2) format('woff2');
}
